import 'bulma/css/bulma.min.css'
import '~/styles/style.css'

import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import lazy from '@loadable/component'

import { NAVBAR_SET } from '~/store'
import { transformSitePage } from '~/libs/es/helper'
import { getDevice } from '~/libs/es/device'

import Navbar from 'components/navbar'

const Aside = lazy(() => import('components/desktop/aside'))
const Footer = lazy(() => import('components/desktop/footer'))

export default connect()(function ({ dispatch, children, location }) {
    const [isPhone, setIsPhone] = useState(false)

    const { site: { siteMetadata }, allSitePage: { nodes } } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }

            allSitePage {
                nodes {
                    path
                    context {
                        id
                        title
                        helmetTitle
                    }
                }
            }
        }
    `)

    useEffect(() => {
        if (getDevice() === 'phone') {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }
    }, [])

    useEffect(() => {
        const { categories, courses, classes } = transformSitePage(nodes)

        dispatch({
            type: NAVBAR_SET,
            payload: { categories, courses, classes }
        })
    }, [siteMetadata, nodes])

    return <>
        <Navbar siteMetadata={siteMetadata} />

        <section className="section" style={isPhone ? { marginTop: '50px', padding: '15px' } : { marginTop: '50px', padding: '25px' }}>
            <Helmet>
                <title>{siteMetadata.helmetTitle}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="author" content={siteMetadata.author} />
                <meta charset="utf-8" />
                <meta property="og:locale" content="th_TH" />
                <link href="https://fonts.googleapis.com/css?family=Kanit&display=swap" rel="stylesheet" />
                <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
            </Helmet>

            <div className="container">
                <div className="columns">
                    <div className="column is-three-quarters" style={isPhone ? { padding: '0px' } : {}}>
                        <div>
                            {children}
                        </div>
                    </div>

                    {
                        !isPhone
                        && <div className="column is-hidden-mobile">
                            <Aside />
                        </div>
                    }
                </div>
            </div>
        </section>

        {
            !isPhone && location.pathname === '/'
            && <Footer />
        }
    </>
})
